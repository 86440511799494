import { Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import React from "react"
import Fade from "react-reveal/Fade"

const BlogItemHeader = ({ coverImage, category, slug, createdDate, creator, title }) => {
    return (
        <div className="container-xxl">
            <div className="row yacht-container">
                <div className="col-12 px-0 col-md-5  ps-inside">
                    <div className="reveal-relative">
                        <Fade right>
                            <GatsbyImage
                                className="overlay-our-yachts-yacht-one blog mt-md-0 mt-4"
                                image={getImage(coverImage)}
                                alt="Yacht image"
                            />
                        </Fade>
                    </div>
                    <StaticImage className="our-yacht-water d-md-none" src="../images/water1.png" alt="water" />
                </div>
                <Fade delay={700} duration={1500}>
                    <div className="col-12  col-md-7 pe-inside z-index-1 d-flex flex-column align-items-start justify-content-center">
                        <div className="category-badge mb-2">{category}</div>
                        <Link style={{ textDecoration: "none" }} to={`/blog/${slug}`}>
                            <h2 className="mb-3">{title}</h2>
                        </Link>

                        <p className="post-time-stamp">
                            {createdDate} BY {creator}
                        </p>
                    </div>
                </Fade>
            </div>
        </div>
    )
}

export default BlogItemHeader
