import { Link } from "gatsby"
import React from "react"
import { ArrowIcon } from "../components/newsletter"

const BlogItemNavigation = ({ next, previous }) => {
    return (
        (next || previous) && (
            <div className="post-nav">
                <div className="container-xxl">
                    <div className="row">
                        <div className="col-6">
                            {previous && (
                                <div className="row pe-md-5">
                                    <div className="" style={{ maxWidth: "fit-content" }}>
                                        <Link
                                            to={`/blog/${previous.slug}`}
                                            className={`button d-flex align-items-center justify-content-center fill light-background button-post previous`}
                                        >
                                            <ArrowIcon className="arrow" />
                                        </Link>
                                    </div>
                                    <div className="col-10">
                                        <div className="row">
                                            <div className="col-12">
                                                <p className="post-prev-next-text mb-4">PREVIOUS</p>
                                                <h4 className="d-none d-md-block">{previous.title}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-6">
                            {next && (
                                <div className="row ps-md-5">
                                    <div className="col-10">
                                        <div className="row">
                                            <div className="col-12">
                                                <p className="post-prev-next-text mb-4">NEXT</p>
                                                <h4 className="d-none d-md-block">{next.title}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="" style={{ maxWidth: "fit-content" }}>
                                        <Link
                                            to={`/blog/${next.slug}`}
                                            className={`button d-flex align-items-center justify-content-center fill light-background button-post next`}
                                        >
                                            <ArrowIcon className="arrow" />
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}

export default BlogItemNavigation
