import { renderRichText } from "gatsby-source-contentful/rich-text"
import { graphql } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import React from "react"
import BlogItemHeader from "../../components/blogItemHeader"
import { Footer } from "../../components/footer"
import { NavBar } from "../../components/header"
import Layout from "../../components/layout"
import Newsletter from "../../components/newsletter"
import Seo from "../../components/seo"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import BackquoteIcon from "../../images/backquote-icon.svg"
import Fade from "react-reveal/Fade"
import BlogItemNavigation from "../../components/blogItemNavigation"

const Post = ({ data, pageContext }) => {
    const { title, slug, coverImage, category, creator, createdDate, body } = data.contentfulBlogPost
    const { next, previous } = pageContext

    const options = {
        renderMark: {
            [MARKS.BOLD]: text => <b className="post-bold">{text}</b>
        },
        renderNode: {
            [INLINES.HYPERLINK]: (node, children) => {
                const { uri } = node.data
                return (
                    <a href={uri} className="underline">
                        {children}
                    </a>
                )
            },
            [BLOCKS.HEADING_3]: (node, children) => {
                return <h3 className="post-sub-header">{children}</h3>
            },
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return <p className="mb-3 mt-3">{children}</p>
            },
            [BLOCKS.QUOTE]: (node, children) => {
                return (
                    <backquote className="blog-backquote">
                        <img src={BackquoteIcon} className="blog-backquote-icon" />
                        {children}
                    </backquote>
                )
            },
            [BLOCKS.EMBEDDED_ASSET]: node => {
                const { gatsbyImageData, description } = node.data.target
                return <GatsbyImage className="my-3" image={getImage(gatsbyImageData)} alt={description} />
            }
        }
    }

    return (
        <Layout>
            <Seo title={title} />

            <div className="top-gradient-container">
                <NavBar image />
                <StaticImage className="bg-contact-water blog d-none d-md-block" src="../../images/water1.png" alt="water" />
                <BlogItemHeader
                    coverImage={coverImage}
                    category={category}
                    title={title}
                    creator={creator}
                    createdDate={createdDate}
                    slug={slug}
                />
            </div>
            <div className="container-xxl post-container">
                <Fade delay={700} duration={1500}>
                    <div className="row">{body && <div className="col-12">{renderRichText(body, options)}</div>}</div>
                </Fade>
            </div>

            <BlogItemNavigation next={next} previous={previous} />
            <Footer />
        </Layout>
    )
}

export default Post

export const pageQuery = graphql`
    query PostItem($slug: String!) {
        contentfulBlogPost(slug: { eq: $slug }) {
            slug
            body {
                raw
                references {
                    ... on ContentfulAsset {
                        contentful_id
                        title
                        description
                        gatsbyImageData(layout: FULL_WIDTH)
                        __typename
                    }
                }
            }
            category
            coverImage {
                gatsbyImageData(layout: FULL_WIDTH)
            }
            createdDate(formatString: "Do MMMM YYYY")
            creator
            title
        }
    }
`
